$green      :  #1A9656;
$red        :  #f54834;
$blue       :  #7245AB;
$black      :  #2d343c;
$black1     :  #000000;
$gray       :  #c5cace;
$skyblue    :  #0082FA;
$skyblue1   :  #0074f5;
$lightgreen :  #67d64b;
$darkgreen  :  #1e844f;
$white      :  #ffffff;
$purple     :  #7245ab;
$textgray   :  #818181;
$gray1      :  #F4F5FA;
$yellow     : #FFC938;

$light_green : #9FEEBE;
$light_red   : #F9C2BD;
$light_skyblue   :  #A3D2FD;

$text_green  : #47945C;

$p1         : 10px;
$p2         : 20px;
$p3         : 30px;

$br4        : 4px;

$m-auto     : 0 auto;