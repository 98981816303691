// Chart 2

.argumentmap{ 
	.argumentmap_progressbar{ background: url('../images/graystrip.svg') repeat-y right top; background-size:20px auto; position:fixed; right:0; top:50px; bottom:0; width:20px; z-index: 2;  /*opacity: .5;*/
		>.p-relative{top: 28px;}
		.step{ margin: 15px 0; cursor: pointer;
			.progressbar{ position:absolute; top: 50%; right:22px; transform: translateY(-50%); height:15px; max-width: 200px; display: none;
				.progress{ border-radius: $br4; border-left:4px solid; font-size: 11px; font-weight: 500; overflow: hidden; padding-left: 5px; line-height: 15px;
					.progress-bar{border-left:4px solid; position: absolute; right: 0; left: auto; text-align: left; border-radius: $br4; height: 16px; padding-left: 5px; padding-bottom: 3px}
				}				
				.action_icon{ position:absolute; left:-18px; top:-2px; background-color: $white; border-radius: 8px 0 0 8px;
					a{padding: 0 0px 0 3px}
					.icon{ height: 14px; width: auto; }
				}
			}
			.start-points, .reasons, .objection, .both, .demand{ color:$white; height: 10px; width: 20px;}
			.start-points{ background:$blue;
				.progressbar{
					.progress{ border-color:$blue; background:#d5c7e9; }
				}
				.action_icon{width: 17px;
					a{ margin-right: 10px;}
				}
			}
			.both, .all{height: 20px;
				&.reasons{background:#11D820;}
				.objection_indecator, .demand_indecator{ margin-top: 10px; height: 10px; width: 20px; }
				.objection_indecator, .demand_indecator{background:#FF6C60; border-bottom: 1px solid $white }
				.demand{background:#FF6C60; }
				.progressbar{
					.progress{ border-color:#68E8B4; background:#A3F1D1; color: $black;
						.progress-bar{border-color:#F79D93; background:#F9CDC8; color: $black;}
					}
					.action_icon{left:-36px;}
				}
			}
			.all{height: 20px;
				.demand_indecator, .objection_indecator{ height:7px; position: absolute}
				.demand_indecator{ background:#11D820; top:-3px }
				.objection_indecator{ top:-10px }
			}
			.reasons{ background:#82D25C;
				.progressbar{
					.progress{ border-color:#68E8B4; background:#A3F1D1; color: $black;
					}
				}
			}
			.demand{ background:$skyblue;
				.progressbar{
					.progress{ border-color:#68B5FC; background:#A3D2FD; color: $black;
					}
				}
			}
			.objection{ background:#FF6C60;
				.progressbar{
					.progress{border-color:#F79D93; background:#F9CDC8; color: $black;}
				}
			}
		}
		&:hover{ opacity: 1;
			&:after{ content:''; position:absolute; right:0; top:0; height: 100%; min-width:270px; z-index: -1; background: rgba($white,.5)}
			.progressbar{ display: block;}
		}
	} 
}

.argumentmap{
	.argumentmap_progressbar{
		.step{
			.p-relative{
						
				&.section-disable{background-color: #878A9D !important;
					.objection_indecator{background-color: #878A9D !important;}
					.progress-bar{border-color: #B9BAC5 !important; background: #D4D5DC !important; color: #888;}
				}
				&.request-disable, &.reason-disable, &.section-disable,&.objection-disable{background-color: #878A9D !important;
					.progressbar{
						.progress{border-color: #B9BAC5 !important; background: #D4D5DC !important; color: #888;}
						.action_icon{
							a.disabled{
								img{filter: grayscale(100%);
								}
							}
						}
					}
				}
				&.objection-indecator-disable{
					.objection_indecator{background-color: #878A9D !important;}
					.progress-bar{border-color: #B9BAC5 !important; background: #D4D5DC !important; color: #888;}
					.progressbar{
						.action_icon{
							a.disabled{
								img{filter: grayscale(100%);
								}
							}
						}
					}
				}
				&.request-black, &.reason-black, &.section-black,&.objection-black{background-color: #000 !important;
					
				}
				&.objection-indecator-black{
					.objection_indecator{background-color: #000 !important;}
				}

				




			}
		}
		
	}
}