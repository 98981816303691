// Chart 1

html, body {font-family: 'Roboto', sans-serif;/*-webkit-print-color-adjust: exact; -webkit-filter: opacity(1) blur(0); */letter-spacing: normal;}
// body{ background:url('../images/bg.png') center top }
a{ text-decoration:none; color:$black1 }
.text-muted{ color: rgba($black1, .5); }
.argumentmap .case_argument.positionup{ z-index:2 }
/* width */
::-webkit-scrollbar {width: 5px; height:5px }
/* Track */
::-webkit-scrollbar-track {background: #f1f1f1; }
/* Handle */
::-webkit-scrollbar-thumb {background: #888; }
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {background: #555; }

.ml-05{ margin-left: 5px }
.sup{vertical-align: super;}
.argumentmap .argumentmap { min-height: 100vh; padding: 80px 0 0; overflow: visible;
	&:before{ display:none }
}
.argumentmap{ position: relative; /*width: 100vw; overflow: auto; */ min-height: 100vh; padding: 0px 30px 30px; font-size: 14px; line-height:130%; /*background:#fff;*/ background:url('../images/bg.png') center top;  /*background: url('../images/divider1.png') repeat-y; background-position: 50% 0px; background-attachment: scroll;*/
/*	&:before{ content:''; position: absolute; left: 0; right:0; top: 0; bottom: 0;  background:url('../images/bg.png') center top;}*/
	&:before{ content:''; position: absolute; left: 50%; margin-left: -1px; width:2px; top: 0; bottom: 0;  background-color:#C8C8C8; z-index:0 }
	/*&:after, &:before{ content:''; position: absolute; left: 50%; margin-left: -7px; width:30px; height:30px; background-color:$white; }
	&:before{top: 50px;}
	&:after{ top:0; }*/
	/*&:before{ bottom:0; }*/
	// &.zoom1{
	// 	&:before{transform:scale(1.1);}
	// }
	// &.zoom2{
	// 	&:before{transform:scale(1.2);}
	// }
	// &.zoom3{
	// 	&:before{transform:scale(1.3);}
	// }
	// &.zoom4{
	// 	&:before{transform:scale(1.4);}
	// }
	// &.zoom5{
	// 	&:before{transform:scale(1.5);}
	// }
	.icon{ width:18px; height:auto; vertical-align:middle }
	p{ margin:5px 0; font-size: 13px; }
	.p-relative{ position:relative; }
	.text-center{ text-align:center }
	.m-auto{ margin: $m-auto }
	.green{background-color: $green}
	.skyblue{background-color: $skyblue}
	.red{background-color: $red}
	ul, ol{ list-style:none; margin-left:0; padding-left:0 }
	.text-right{text-align: right;}

	.action_icon{ position:absolute; top:-40px;
		img{ width:20px }
		&.add{ right:-10px }
		&.close{ left:-10px }
	}

	.btn{ background:$white; color:$black1; padding:0px 8px; border-radius:$br4; font-size:13px; margin-right:5px;
		&:hover{ opacity:.8 }
		&.disabled, &.disabled:hover{ background:rgba($white, .8)}
	}
	.card{border-radius:$br4; color: $white; padding:$p1;width:250px; position: relative;}

	.start-points{background-color: $purple; width: 30%; max-width:200px; top: 0px; margin: 10px auto 30px; position: relative; z-index: 1; 
		&.card{padding: 10px 30px; border:0 !important}
		.search_section{ position:absolute; top:50%; left:calc(100% + 20px); background:$black1; border-radius: 7px; overflow: hidden; transform: translateY(-50%);
			.search_field{ min-width:250px; display: flex; align-items: center;
				input{ background:url('../images/search.svg') no-repeat; background-size: 18px auto; background-position:10px center; padding-left:35px; color:$white; border:0; outline:0; box-shadow:none;  }
				span{ display:inline-block;min-width:20px; background-color:#888; margin-right:5px; border-radius: 4px; }
				::-webkit-input-placeholder { /* Edge */ color: $white; } 
				:-ms-input-placeholder { /* Internet Explorer 10-11 */ color: $white; } 
				::placeholder {color: $white; }
			}
		}
	}
	.baseline{ position:fixed; top:100px; height:calc(100vh - 130px); left: 50%; width: 6px; margin-left: -3px; background-color: $gray; z-index:0; }
	h6{ padding-right:25px }
	.reasons{
		.comment_icon{background-color:rgb(95, 182, 137);}
	}
	.objection{
		.comment_icon{background-color:rgb(248, 127, 113);}
	}
	.demand{
		.comment_icon{background-color:rgb(77, 168, 252);}
	}
	.comment_icon{position:absolute; top:0px; right: 0px; padding: 2px 5px 5px 5px; background-color: rgba($white, .3); border-radius: $br4; z-index: 1;
		>a{ position:relative;
		}
		img{ width:14px }
		&:hover{
			.messagebox{ display: block;}
		}
	}
	.case_argument{ position: relative; margin:0px  0; z-index: 1;
	/*&:nth-child(odd) { background:#ccc}
	&:nth-child(even) { background:#e1dfdf}*/
		.reasons, .objection, .demand{ width:calc(50% - 36px); position: absolute; top: 50%; transform: translateY(-50%);
			.p-relative{
				&:after, &:before{ content:''; position:absolute; top:50%;}
				&:after{width:30px; height:2px; /*margin-top:0px;*/ background-color:#C5CACE; z-index: -1; }
				&:before{width:10px; height:16px; margin-top:-8px;}
			}
			.sublist_openclose{ position:absolute; top:50%; transform: translateY(-50%); width:16px; height:16px; z-index: 1; /*background-image: url('../images/plus-icon.png'); background-position: 4px center; background-repeat: no-repeat;*/ cursor: pointer;
				&:after, &:before{content:''; position:absolute; background: $white; left: 50%; top: 50%; transform: translate(-50%, -50%)}
				&:after{ width:7px; height:1px; margin-left: 1px; }
				&:before{ width:1px; height:7px;margin-left: 1px; }
				&.open{/*background-image: url('../images/minus-icon.png'); background-position: 3px center;*/
					&:after{margin-left: -1px; }
					&:before{ display:none }
				}
			}
		} 
		.reasons{ left:0; margin-right:30px;
			.sublist_openclose{ left: -16px; border-radius: 16px 0 0 16px;background-color:#0D6E27 !important;
				&:after{margin-left: 1px !important; }
				&:before{margin-left: 1px !important; }
			}
			.p-relative{
				&:after{ right:-46px }
				&:before{ background:#0D6E27; right: -20px; border-radius:0 16px 16px 0;  }
			}
			.sublist_openclose{
				&.open{background-position: 6px center !important; }
			}
			.card{ float:right; border:0 !important }
			.sublist{right: calc(100% + 50px);
				.link{ right: 0;}
				&:before{ right:-25px; border-top:2px solid $green !important; border-left:2px solid $green !important; box-shadow: -3px -1px 0 -2px $white; -moz-box-shadow: -3px -1px 0 -2px $white; -webkit-box-shadow: -3px -1px 0 -2px $white; z-index: 1;}
				&:after{ right:-25px; border-bottom:2px solid $green !important; border-left:2px solid $green !important; margin-top: -6px; box-shadow: -4px 1px 0 -1px $white; -moz-box-shadow: -4px 1px 0 -1px $white; -webkit-box-shadow: -4px 1px 0 -1px $white;}
				.link{ right: 0;
					a{ padding: 0; text-align: center; line-height: 23px;}
				}
				&:before{ right:-25px; /*background-color: $green !important;*/}
				.connector{border:2px solid $green; right:0; border-radius:0 10px 10px 0;  border-left:0 !important; 
					&:after, &:before{ right:16px; background-color: $green}
				}
				ul{ margin-right:30px;
					>li{
						>a{}
						&:before{ right:-30px; border-radius: 0 8px 0 0;  border-left:0 !important; border-color: $green}
						&:after{right:-11px; background: $green}
					}
				}
			}
			.argument_txt{ text-align:right; }
		&.demand{
			.sublist{
				&:before{border-top: 2px solid $skyblue !important; border-left: 2px solid $skyblue !important;}
				&:after{border-bottom: 2px solid $skyblue !important; border-left: 2px solid $skyblue !important;}
			}
		}
		}
		.demand{ left:0; margin-right:30px;
			.sublist_openclose{ left: -16px; border-radius: 16px 0 0 16px;background-color:#1C59B9 !important;
				&:after{margin-left: 1px !important; }
			}
			.p-relative{
				&:after{ right:-46px }
				&:before{ background:#1C59B9; right: -20px; border-radius:0 16px 16px 0;  }
			}
			.card{ float:right; border:0 !important }
			.sublist{right: calc(100% + 50px);
				.link{ right: 0;}
				&:before{ right:-25px; background-color: $green !important;}
				.connector{border:2px solid $green; right:0; border-radius:0 10px 10px 0;  border-left:0 !important; 
					&:after, &:before{ right:16px; background-color: $green}
				}
				ul{ margin-right:30px;
					>li{
						>a{}
						&:before{ right:-30px; border-radius: 0 8px 0 0;  border-left:0 !important; border-color: $skyblue}
						&:after{right:-11px; background: $skyblue}
					}
				}
			}
			.argument_txt{ text-align:right; }
		}

		.objection{ left:50%; margin-left:36px; z-index: -1;
			.objection_cnt {
				.card{ border:0 !important }
			}
			.sublist_openclose{ right: -16px; border-radius: 0 16px 16px 0;background-color:#B72109 !important;
				&:after{margin-left: -1px !important; }
				&:before{margin-left: -1px !important; }
			}	
			.p-relative{
				&:after{ left:-46px; margin-top: 1px; }
				&:before{background:#B72109; left: -20px;  border-radius:16px 0 0 16px }
			}
			&:after{ left:0 }
			&:before{}
			.sublist{left: calc(100% + 50px);
				.link{ left: 0;
					a{ padding: 0; text-align: center; line-height: 22px;}
				}
				&:before{ left:-25px; border-top:2px solid $red; border-right:2px solid $red;  border-radius: 0 10px 0 0 !important; box-shadow:4px 0px 0 -1px #fff; z-index:1}
				&:after{left:-25px; border-bottom:2px solid $red; border-left: 0; border-right:2px solid $red; border-radius: 0 0 10px 0 !important; margin-top: -6px; box-shadow: 2px 2px 0 0px #fff}
				.connector{border:2px solid $red; left:0; border-radius:10px 0 0 10px;  border-right:0 !important;
					&:after, &:before{ left:16px;}
				}
				ul{ margin-left:30px;
					>li{
						>a{}
						&:before{ left:-30px; border-radius:8px 0 0 0;  border-right:0 !important;}
						&:after{left:-11px;}
					}
				}
				.argument_txt{
					.number{ background:#F9C2BD; color: $red;}
					.nm {color: $red; }
				}
			}
			&.multiple{ margin-left:20px; margin-top: 30px;
				>.connector{  position: absolute; left: -1px; border: 2px solid $gray; width:30px; margin-top:-27px; top: 50%; transform: translateY(-50%); border-radius: 10px 0 0 10px; border-right: 0 !important; z-index: -1;
					&:after, &:before{ content:''; position:absolute; left:0; }
					&:after{top:50%; transform:translateY(-50%); width:20px; height:8px; /*background-color: $gray;*/ border-top:2px solid $gray; border-right:2px solid $gray;  border-radius: 0 10px 0 0 !important; box-shadow:4px 0px 0 -1px #fff; left:-20px; margin-top: 0;}
					&:before{top:50%; transform:translateY(-50%); width:20px; height:8px; /*background-color: $gray;*/ border-bottom:2px solid $gray; border-right:2px solid $gray;  border-radius: 0 0 10px 0 !important; box-shadow:4px 0px 0 -1px #fff; left:-20px; margin-top:-6px;}
				}
				.objection_cnt{ position:relative; margin-left: 20px;
					.card{ border:0 !important}
					&.first, &.last{ 
						.p-relative:after{ display:none !important }
					}
				}
				.p-relative:after {left: -30px; width: 10px; margin-top: 2px;}
			}
		}

		.sublist{position: absolute; top: 50%; transform: translateY(-50%); text-shadow: 0 0 2px $white; 
			&:before, &:after{content: ''; position:absolute; top: 50%; /*transform:translateY(-50%);*/ width: 27px; /*height: 2px; background-color: $red;*/ height: 8px; display: none; }
			&:after{border-radius:  0 0 0 10px}
			&:before{border-radius: 10px 0 0 0}

			.connector{ position:absolute; top:10px; width:20px; display: none; 
				&:after, &:before{ content: ''; position:absolute; width: 5px; height: 5px; background-color: $red; transform: rotate(45deg); display: none}
				&:after{ top:-4px }
				&:before{ bottom:-4px }

			}
			ul{ min-width:130px; font-size: 13px; font-weight: 400; display: none;
				>li{ position: relative; margin: 30px 0 50px; padding-top: 30px;
					&:first-child{ margin-top:0 }
					&:last-child{ margin-bottom:0 }
					a{ display:inline-block; padding:10px; color: $textgray}
					&:before, &:after{content: ''; position:absolute;}
					&:before{ top: 38px; width: 20px; height: 10px; border:2px solid $red;  border-bottom: 0 !important}
					&:after{ top: 37px; width: 5px; height: 5px; background-color: $red; transform: rotate(45deg)}
					&:first-child:before, &:last-child:before, &:first-child:after, &:last-child:after{ display:none !important }
				}
			} 
			.argument_txt{ min-width: 200px;
				.heading, p{ color:$black }
				.number{ padding:0 7px; border-radius: $br4; background:#D7FCE8; color:$green; }
				.nm{color:$green;}
			}
			.link{border-radius: 100%; background:#ECF3FC; width: 24px; height: 24px; line-height: 20px; text-align: center; position: absolute; top: 0px;
				img{ width:12px }
				&:hover{ background:#C2DBFA }
			}
			&.open{
				.connector{ display: block; top: 40px;
					&:after, &:before{ display: block;}
				}
				ul{ display:block; position: relative; top: 0px;margin-bottom:0px; }
				&:before, &:after{ display: block;}
			}
		}

	}
}

.argumentmap .case_argument {
	.reasons, .demand, .objection{ 
		.sublist{
			&.single{ margin-top: -28px;
				&:before{display: none;}
				.connector{ border-bottom: 0; border-bottom-right-radius: 0; border-bottom-left-radius: 0;
					&:before{ content:''; position:absolute; bottom: -6px; width:28px; height:15px; background:$white !important; transform:rotate(0)  }
				}
				&:after{ display:none !important }
			}
		}
	}

	.reasons, .demand{ 
		.sublist{
		/*119*/
			&.single{
				.connector{
					&:before{right: -28px; border-bottom-left-radius: 10px; border-left: 2px solid;  border-bottom: 2px solid; box-shadow:-2px 2px 0 1px $white }
				}
			}
		}
	}
	.reasons{ 
		.sublist{
		/*119*/
			&.single{
				.connector{
					&:before{ border-color:$green !important;}
				}
			}
		}
	}
	.demand{ 
		.sublist{
		/*119*/
			&.single{
				.connector{
					&:before{border-color:$skyblue !important;}
				}
			}
		}
	}

	.objection{ 
		.sublist{
		/*119*/
			&.single{
				.connector{
					&:before{left: -28px; border-bottom-right-radius: 10px; border-right: 2px solid $red;  border-bottom: 2px solid $red; box-shadow:2px 2px 0 1px $white  }
				}
			}
		}
	}
	.demand{
		.sublist{
			&:before{background-color:$white !important}
			.connector{ border-color:$skyblue !important;
				&:before, &:after{background-color:$skyblue !important}
			}
		}
	}
}
.disabledCursor { 
  cursor: no-drop;
}
#report{
 		position:relative;
		&:after{ content:''; position: absolute; left: 50%; margin-left: -1px; width:2px; top: 0; bottom: 0;  background-color:#C8C8C8; }

}

.argumentmap{
	&.zoom0, &.zoom1, &.zoom2, &.zoom3, &.zoom4, &.zoom5, &.zoom-5, &.zoom-4, &.zoom-3, &.zoom-2, &.zoom-1{
		.argumentmap-zoom{transform-origin: top center;
		 	
		}


	}
	&.zoom1{ width:103vw;}
	&.zoom2{ width:106vw;}
	&.zoom3{ width:109vw;} 
	&.zoom4{ width:112vw;} 
	&.zoom5{ width:115vw;}
	&.zoom0{
		.argumentmap-zoom{ transform: scale(1); }
	}
	&.zoom1{
		.argumentmap-zoom{ transform: scale(1.05); }
	}
	&.zoom2{
		.argumentmap-zoom{ transform: scale(1.10); }
	}
	&.zoom3{
		.argumentmap-zoom{ transform: scale(1.15); }
	}
	&.zoom4{
		.argumentmap-zoom{ transform: scale(1.20); }
	}
	&.zoom5{
		.argumentmap-zoom{ transform: scale(1.25); }
	}
	&.zoom-5{
		.argumentmap-zoom{ transform: scale(0.95); }
	}
	&.zoom-4{
		.argumentmap-zoom{ transform: scale(0.90); }
	}
	&.zoom-3{
		.argumentmap-zoom{ transform: scale(0.85); }
	}
	&.zoom-2{
		.argumentmap-zoom{ transform: scale(0.80); }
	}
	&.zoom-1{
		.argumentmap-zoom{ transform: scale(0.75); }
	}
}
.argumentmap {
	&.zoom1 {
		.kpifeature_section {
			width: 104vw;
		}
	}
	&.zoom2 {
		.kpifeature_section {
			width: 109vw;
		}
	}
	&.zoom3 {
		.kpifeature_section {
			width: 115vw;
		}
	}
	&.zoom4 {
		.kpifeature_section {
			width: 121vw;
		}
	}
	&.zoom5 {
		.kpifeature_section {
			width: 126.7vw;
		}
	}
}
.hide{display:none}


.messagebox{position:absolute !important; z-index: 3; width:300px !important; top:-40px; left: calc(100% + 30px); background: $white; color: $black; box-shadow:0px 10px 15px 3px rgba($black, .3); border-radius: 5px; color:$black; padding: 0 !important; border: none; overflow: hidden;
	h5{ font-size:14px; margin:0; color:$black;  }
	p{ font-size:13px; color:$black;  }

	.msg_title { display:flex; align-items:self-end; position: relative; padding: 10px; border-bottom: 1px solid #EFF3F5;
		.close{ position:absolute; right:10px; top:8px;
			img{width:10px; height:auto }
		}
	}
	.msg_body{ padding: 20px 0px 0px; border-bottom: 1px solid #EFF3F5; max-height: 320px; overflow: auto; position: relative;
		.comment_content{border-bottom: 1px solid #EFF3F5; padding-bottom: 0px; margin-bottom: 10px; 
			>p{ margin-left:50px; padding-right:10px }
			.comment_content_header{ display: flex; align-items: center; justify-content: space-between; width: 100%; position: relative; color: $black1; padding: 0 10px;
				
				.text-muted{ font-size:8px }
			}
			.profiledtl{display: flex; align-items: center; margin-bottom: 5px;
				.profileimg{ width: 30px; height: 30px; text-align: center; line-height: 30px; border-radius: 100%; overflow:hidden; border: 1px solid rgba($gray, .5); margin-right: 10px; display: inline-block; vertical-align: middle;
					img{ width:100%; max-height:100%; object-fit: contain; }
				}
				span, strong{ line-height:100% }
			}
			&:last-child{ border-bottom:0; margin-bottom:0 }
			.more{ position:absolute; right:10px; top:-10px;
				>a{ display: block;
					img{ width:18px; height:auto }
				}
				.more_cnt{ position:absolute; left:-120px; top:-10px; background-color: $white; min-width: 110px; border-radius: 2px; box-shadow: 0 5px 15px 2px rgba($gray, 1);
					&:before{ content:''; position:absolute; border-radius: 2px; right: -4px; top: 13px; width: 13px; height: 13px; background-color: $white; transform: rotate(45deg) skew(-10deg, -10deg);}
					>div{ border-bottom:1px solid #ECF2F5;
						&:last-child{ border-bottom:0 }
					}
					a{ display:block; padding:7px; font-size:10px }
					img{ width: 16px; margin-right: 3px}
				}
			}
			&:last-child{ margin-bottom:0; padding-bottom:0 }
		}
		.reply{	
			&__action{ font-size:12px; display: inline-block; padding: 5px 5px 5px 10px;
				.icon {width: 13px; margin-right: 2px}
			}
			&__msg{
				ul{ margin: 0px; padding:10px; background:url('../images/arrow-reply.svg') #F4F8FA no-repeat; background-position: 20px 55px; background-size: 16px auto;
					li{margin-bottom:15px; margin-left: 40px; position: relative; background:url('../images/comments.png') #F4F8FA no-repeat; background-position: 10px 48px; background-size: 10px auto; 
						.profileimg {width: 30px; height: 30px; text-align: center; line-height: 30px; border-radius: 100%; overflow: hidden; border: 1px solid rgba(197, 202, 206, 0.5); margin-right: 10px; display: inline-block; vertical-align: middle;
							img {width: 100%; max-height: 100%; object-fit: contain; }
						}
						strong{ color:$black1; margin-right:5px }
						.text-muted{ font-size: 8px; margin-top: 0px}
						.cnt{ color:rgba($black1, .7); padding-left: 40px;
							p, strong{ font-size: 12px; line-height: 120%; margin: 0}
						}
						.profiledtl{
							strong{ font-size:90% }
						}
						.more{ top:3px; right:0 }
						&:last-child{ margin-bottom:0 }
					}
				}
			}
			&__area{ margin-top: 10px;
				.inputreply{ margin-top: 5px; border: 1px solid #EFF3F5; width:100%; outline:none; padding:5px; font-size:12px }
			}
			&.hr{border-top: 1px solid #EFF3F5; /*border-bottom: 1px solid #EFF3F5; margin-top: 10px;*/ }
		}
	}
	.msg_footer{ 
		.form{ position:relative; padding: 15px; background-color: #F4F8FA; border-bottom: 8px solid #fff;
			input{ width:100%; background:#fff; border:1px solid transparent; padding: 5px 30px 5px 5px; font-size: 12px; 
			&:focus-visible {
				outline: initial !important;
			}
			}
			button{ position:absolute; right:18px; top:16px; background-color: transparent; border: 0;
				img{ width:14px; height:auto }
			}
		}
	}
}
.case_argument{ position: relative; margin: 50px 0; z-index: 1;
		.reasons, .demand{ width:calc(50% - 36px); position: absolute; top: 50%; transform: translateY(-50%);
			&.section-disable{
				.card{
					&.green, &.skyblue, &.red{ background: $black;}
					.comment_icon{ background:rgba(255, 255, 255, .15) }
					.p-relative{
						&:before{ background: $black;	}
					}
					.sublist_openclose{background: $black !important; }
				}
				.sublist{
					.connector{ border-color:$black !important;
						&:after, &:before{background-color: $black !important; border-color:$black !important;}
					}
					&:before, &:after{ border-color:$black !important }
					.argument_txt{
						.number{background: rgba($black, .2); color: $black;}
						.nm{color:$black !important}
					}
					.link{background: rgba($black, .2);
						img{ filter: grayscale(100%); }
					}
					&.single{
						.connector{
							&:before{border-color: $black !important;}
						}
					}
					ul{
						&:after{ content:'' ; background:rgba($white, .8) !important; position: absolute; left: 0; top: 0px; right: -30px; height: calc(100% + 30px); z-index: 1;}
						>li{
							&:before{border-color:$black !important;}
							&:after{background:$black !important;}	
						}
					}
				}				
				.action_icon{
					img{filter: grayscale(100%); }
				}
				&:after{ content:'' ; background:rgba($white, .8) !important; position: absolute; width:325px; right: -35px; top:-30px; height: calc(100% + 30px); z-index: 1;}
			}
		}
		.objection{
			.objection_cnt{
				&.section-disable{ position: relative;
					.card{
						&.red{ background: $black;
							.sublist_openclose{background: $black !important;}
							.p-relative{
								.comment_icon{ background:rgba(255, 255, 255, .15) }
								.action_icon{
									img{filter: grayscale(100%); }
								}
								&:before{background: $black;}

								.sublist{
									.connector{ border-color:$black !important;
										&:after, &:before{background-color: $black !important; border-color:$black !important;}
									}
									&:before, &:after{ border-color:$black !important }
									.argument_txt{
										.number{background: rgba($black, .2); color: $black;}
										.nm{color:$black !important}
									}
									.link{background: rgba($black, .2);
										img{ filter: grayscale(100%); }
									}
									&.single{
										.connector{
											&:before{border-color: $black !important; background: none !important}
										}
									}
									ul{
										&:after{ content:'' ; background:rgba($white, .8) !important; position: absolute; right: 0; top: 0px; left: -30px; height: calc(100% + 30px); z-index: 1;}
										>li{
											&:before{border-color:$black !important;}
											&:after{background:$black !important;}	
										}
									}
								}	
							}
						}
					}
					&:after{ content:'' ; background:rgba($white, .8) !important; position: absolute; width: 325px; left: -35px; top: -30px; height: calc(100% + 30px); z-index: 1;}
				}
			}
		}
	}

.mark, mark{background-color:$black1; color:$white; border-radius:3px;padding: 0 3px 0 2px; line-height: 100%;}

.pageload{ display: none;position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,.9); z-index: 4; filter: blur(5px);
	img{ position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 50px; height: auto }
	&.open{display: block}
}

.section-disable,.objection-disable,.reasons-disable,.demand-disable{
	.mark, mark{background-color:transparent !important; color:$white; border-radius:0;padding: 0; line-height: normal;}
	ul, .btn{ 
		.mark, mark{color: $black !important }
	}
}


@media only screen and (max-width: 1100px) {
	.argumentmap{
		.card{ width: 220px;}
		.case_argument .sublist .argument_txt{min-width: 160px}
		/*.case_argument{
			.reasons, .objection, .demand{
				.p-relative:after{width: 30px;}
			}
			.objection{ margin-left: 25px;
				.sublist {left: calc(100% + 40px);
				}
				.objection_cnt.section-disable:after{ width: 260px; left: -10px;}
			}
			.reasons, .demand{margin-left: 10px;
				.sublist {right: calc(100% + 40px); }
				&.section-disable:after{ width: 260px; right: -10px;}	
			}
			.objection .objection_cnt.section-disable .card.red .p-relative .sublist ul:after{ height: calc(100% + 50px);}
		}*/
		.case_argument .objection .objection_cnt.section-disable:after, .case_argument .reasons.section-disable:after, .case_argument .demand.section-disable:after{ width:295px; height: calc(100% + 50px); }
		.case_argument .objection .objection_cnt.section-disable .card.red .p-relative .sublist ul:after{ height:calc(100% + 50px) }
	}
} 

.bgnone{
	background:none;
}
.comment_icon{
	.comment_count{position:absolute; top: 1px; left: 34px; padding: 0 2px; background-color: $yellow; border-radius: 3px; font-weight: bold; font-size: 10px; min-width: 16px; text-align: center; color: $white; /*display: none;*/
		&:before{ content:''; position:absolute; border-radius: 1px; left: -3px; top: 6px; width: 7px; height: 7px; background-color: $yellow; transform: rotate(45deg);}
	}
	/*&:hover{
		.comment_count{ display:block }
	}*/
}