@font-face {
    font-family: 'Tommaso-turkish';
    src: url('Tommaso-turkish.eot');
    src: url('Tommaso-turkish.eot?#iefix') format('embedded-opentype'),
        url('Tommaso-turkish.woff2') format('woff2'),
        url('Tommaso-turkish.woff') format('woff'),
        url('Tommaso-turkish.ttf') format('truetype'),
        url('Tommaso-turkish.svg#Tommaso-turkish') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

