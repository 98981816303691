.loader {
        display: flex;
        justify-content: center;
        align-items: center;
        /*position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);*/
        height: 100px;
  }
  
  .spinner {
    border: 5px solid #3498db;
    border-top: 8px solid transparent;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s infinite linear;
    margin:0 auto 20px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }